'use strict';

// STICKY
function scrollFade(){
  const textPosition = $('.statement__lead__text--1').offset().top;
  const scroll = $(window).scrollTop();
  const windowHeight = $(window).height();
  if(scroll > textPosition - windowHeight + 50 + windowHeight / 2){
    $('.statement__lead__ttl--1').addClass('is-fadein');
  }
  else{
    $('.statement__lead__ttl--1').removeClass('is-fadein');
  }

  const textPosition2 = $('.statement__lead__text--2').offset().top;
  const scroll2 = $(window).scrollTop();
  const windowHeight2 = $(window).height();
  if(scroll2 > textPosition2 - windowHeight2 + windowHeight2 / 2){
    $('.statement__lead__ttl--1').removeClass('is-fadein');
    $('.statement__lead__ttl--2').addClass('is-fadein');
  }
  else{
    $('.statement__lead__ttl--2').removeClass('is-fadein');
  }
}

$(function(){
  $(window).on('scroll', function(){
    scrollFade();
  })
});
